/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useAuthState } from "react-firebase-hooks/auth";
import { apiURL } from "../api-config";
import { auth } from "../firebase-config";
import { ReactComponent as DownloadIcon } from "../images/download-icon.svg";
import { ReactComponent as FilePlusIcon } from "../images/file-plus-icon.svg";
import "./Demo.scss";

function Demo() {
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [user] = useAuthState(auth);

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleUpload = async () => {
    setLoading(true);
    setTimeout(async () => {
      if (!user) {
        setAnalysisResult({
          Error: "Please login to upload a scan.",
        });
        setLoading(false);
        return;
      }

      const form = new FormData();
      if (selectedFiles)
        selectedFiles.forEach((file) => form.append("files", file));

      await fetch(`${apiURL}/scans`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
        body: form,
      })
        // log response from .net return Ok(result);
        .then((response) => {
          // check if response is json
          if (!response.ok) {
            return response.text();
          }

          return response.json();
        })

        .then((data) => {
          setAnalysisResult(data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);

          // on 403 ask to login
          setAnalysisResult({
            Error: "Error uploading scan. Please try again later.",
          });
        });

      setLoading(false);

      setTimeout(() => {
        const element = document.getElementById("analysis-result");
        element?.scrollIntoView({ behavior: "smooth" });
      }, 200);
    }, 800);
  };

  return (
    <div className="Demo page">
      <div className="title-container">
        <h1 className="title">Demo</h1>

        <h2 className="subtitle">Try out AbdomAID by uploading a CT-Scan.</h2>
      </div>
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive ? "dragging" : ""} ${
          selectedFiles ? "selected" : ""
        }
        `}
      >
        <input {...getInputProps()} id="file-input" />
        {
          // eslint-disable-next-line no-nested-ternary
          selectedFiles ? (
            <div className="file-info">
              {/* EG 44 files selected */}
              <h3 className="file-count">
                {selectedFiles.length} file{selectedFiles.length > 1 && "s"}{" "}
                selected
              </h3>
            </div>
          ) : isDragActive ? (
            <>
              <FilePlusIcon width={70} height={70} />
              <p>Add CT-scan</p>
            </>
          ) : (
            <>
              <DownloadIcon width={70} height={70} />
              <p>Drag and drop your CT-scan here. Or click to select files.</p>
            </>
          )
        }
      </div>
      <button
        type="button"
        onClick={handleUpload}
        className={`primary inverted ${loading ? "loading" : ""}`}
        disabled={loading}
      >
        UPLOAD SCAN
      </button>

      {analysisResult && (
        <div className="analysis-result" id="analysis-result">
          <h2 className={analysisResult?.error ? "error" : ""}>
            Upload {analysisResult?.error ? "Failed" : "Complete"}
          </h2>

          {analysisResult?.error ? (
            <p>
              <strong>Error:</strong> {analysisResult.error}
            </p>
          ) : (
            <p>
              Click{" "}
              <a
                href={`/result/${analysisResult.patientId}`}
                className="result-page-link"
              >
                here
              </a>{" "}
              to view the analysis.
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default Demo;
