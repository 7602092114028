import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AWS from "aws-sdk";
import "./Result.scss";

function Result() {
  const { id } = useParams();
  const [patientIMGs, setPatientIMGs] = useState(null);
  const [patientPredHematoma, setPatientPredHematoma] = useState(Array(36));
  const [patientPredTrauma, setPatientPredTrauma] = useState(0);
  const [maxPredHematoma, setMaxPredHematoma] = useState(0);
  const [analysisComplete, setAnalysisComplete] = useState(false);

  useEffect(() => {
    // poll s3 every 5 seconds until prediction_hematoma.txt is available
    const fetchData = async () => {
      // eslint-disable-next-line no-console
      console.info("polling s3");

      // Configure AWS SDK
      AWS.config.update({
        region: "us-east-2",
        accessKeyId: "AKIA4RRRPLTVMKNAKHV3",
        secretAccessKey: "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",
      });

      // Create an S3 instance
      const s3 = new AWS.S3();

      // list all objects under patient_data/id_{id}/
      const listParams = {
        Bucket: "abdomaid",
        Prefix: `patient_data/id_${id}/`,
      };

      s3.listObjectsV2(listParams, (err, data) => {
        if (err) {
          console.error(err);
        } else {
          if (data.Contents.length === 0) {
            return;
          }

          // patient_data/id_{id}/*.png
          setPatientIMGs(
            data.Contents.filter((object) => object.Key.endsWith(".png")).map(
              (object) =>
                `https://abdomaid.s3.us-east-2.amazonaws.com/${object.Key}`,
            ),
          );
          console.log(patientIMGs);

          // get predication_hematoma.txt content
          const patientPredHematomaTXT = data.Contents.filter((object) =>
            object.Key.endsWith("predictions_hematoma.txt"),
          ).map((object) => object.Key);
          if (patientPredHematomaTXT.length > 0) {
            const getParams = {
              Bucket: "abdomaid",
              Key: patientPredHematomaTXT[0],
            };
            s3.getObject(getParams, (getErr, getData) => {
              if (getErr) {
                console.error(getErr);
              } else {
                setPatientPredHematoma(
                  getData.Body.toString().split("\n").slice(0, -1),
                );
                setMaxPredHematoma(
                  Math.max(
                    ...getData.Body.toString()
                      .split("\n")
                      .slice(0, -1)
                      .map(Number),
                  ),
                );
              }
            });
            setAnalysisComplete(true);
          }

          // get predication_trauma.txt content
          const patientPredTraumaTXT = data.Contents.filter((object) =>
            object.Key.endsWith("predictions_trauma.txt"),
          ).map((object) => object.Key);
          if (patientPredTraumaTXT.length > 0) {
            const getParams = {
              Bucket: "abdomaid",
              Key: patientPredTraumaTXT[0],
            };
            s3.getObject(getParams, (getErr, getData) => {
              if (getErr) {
                console.error(getErr);
              } else {
                setPatientPredTrauma(
                  Number(getData.Body.toString().split("\n").slice(0, -1)),
                );
              }
            });
          }
        }
      });
    };

    if (!analysisComplete) {
      const interval = setInterval(fetchData, 5000);
      return () => clearInterval(interval);
    }

    return () => {};
  }, [id, analysisComplete, patientPredHematoma]);

  if (!analysisComplete) {
    return (
      <div className="Result page">
        <div>
          <h1>Patient #{id} Analysis</h1>
          <h2>
            Upload complete. Scan analysis in progress
            <span className={`${!analysisComplete ? "loading" : ""}`} />
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div className="Result page">
      <div>
        <h1>Patient #{id} Analysis</h1>
        <h2>
          Liver Hematoma:{" "}
          <span style={{ color: maxPredHematoma > 0.5 ? "red" : "green" }}>
            {maxPredHematoma > 0.5 ? "Positive" : "Negative"}
          </span>
        </h2>
        <h2>
          Liver Trauma:{" "}
          <span style={{ color: patientPredTrauma > 0.5 ? "red" : "green" }}>
            {patientPredTrauma === 0
              ? "Negative"
              : patientPredTrauma === 1
              ? "Moderate"
              : "Severe"}
          </span>
        </h2>
      </div>

      <h1>Liver Slices with Probabilities of Hematoma Presence</h1>
      <ul className="liver-slices">
        {patientIMGs &&
          patientIMGs.map((object, index) => (
            <div key={object}>
              <p>Slice {index + 1}</p>
              <p>
                {" "}
                Hematoma Probability:{" "}
                {Math.round(patientPredHematoma[index] * 100)}%
              </p>
              <img src={object} alt={`img_${index}`} />
            </div>
          ))}
      </ul>
    </div>
  );
}

export default Result;
