import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import preval from "preval.macro";

import Home from "./components/Home";
import NotFound from "./404";
import About from "./components/About";
import HomeV0 from "./components/Home-v0";
import PageLayout from "./PageLayout";
import Demo from "./components/Demo";
import Register from "./components/Register";
import Team from "./components/Team";
import Login from "./components/Login";
import Profile from "./components/Profile";
import Logout from "./components/Logout";
import Reset from "./components/Reset";
import Help from "./components/Help";
import Admin from "./components/Admin";
// import Messages from "./components/Messages";
import Result from "./components/Result";

const buildDate = preval`module.exports = new Date().toLocaleDateString('en-US', {year: 'numeric', month: 'short', day: 'numeric'})`;
const buildNumber = preval`module.exports = process.env.CODEBUILD_BUILD_NUMBER || 'local'`;

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PageLayout />}>
          <Route path="/about" element={<About />} />
          <Route path="/v0" element={<HomeV0 />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/team" element={<Team />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/help" element={<Help />} />
          <Route path="/admin" element={<Admin />} />
          {/* <Route path="/messages" element={<Messages />} /> */}
          <Route path="/result/:id" element={<Result />} />
          <Route path="/" element={<Home />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <p className="build-date">
        {buildDate} #{buildNumber}
      </p>
    </BrowserRouter>
  );
}

export default App;
