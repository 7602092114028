import React from "react";
import "./Logout.scss";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase-config";

function Logout() {
  const [user] = useAuthState(auth);
  const [loading, setLoading] = React.useState(true);

  if (user) {
    auth.signOut();
  }

  // psuedo-loading
  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <div className="Logout page">
      <div className="title-container">
        {loading ? (
          <>
            <h1 className="title">Logging Out...</h1>
            <h2 className="subtitle">Please wait.</h2>
          </>
        ) : (
          <>
            <h1 className="title">Logged Out</h1>
            <h2 className="subtitle">You have been logged out.</h2>
          </>
        )}
      </div>
    </div>
  );
}

export default Logout;
