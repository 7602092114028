import "./Home-v0.scss";
import React from "react";

function Home() {
  return (
    <div className="Home-v0">
      <header className="header">AbdomAID</header>
      <div className="body">
        <p>
          Tired of misdiagnosing your patients?
          <br />
          <code className="rainbow">AbdomAID</code> is a software application
          that uses machine learning to help you diagnose your patients.
          <br />
          Get it now for <i className="price">$0.99!!!</i>
        </p>
      </div>
    </div>
  );
}

export default Home;
