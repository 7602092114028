import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { apiURL } from "../api-config";
import { auth } from "../firebase-config";
import "./Admin.scss";

function Admin() {
  const [scans, setScans] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [status, setStatus] = React.useState({});
  const [hoveredImageKey, setHoveredImageKey] = React.useState(null);
  const [delayHandler, setDelayHandler] = useState(null);
  const [deleted, setDeleted] = React.useState([]);
  const [deletedScans, setDeletedScans] = React.useState([]);
  const [admin] = useAuthState(auth);

  const handleMouseEnter = (scanId) => {
    setDelayHandler(
      setTimeout(() => {
        setHoveredImageKey(scanId);
      }, 1000),
    );
  };

  const handleMouseLeave = () => {
    clearTimeout(delayHandler);
    setHoveredImageKey(null);
  };

  const deleteUser = async (userId) => {
    fetch(`${apiURL}/users/${userId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${await admin?.getIdToken()}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        setDeleted([...deleted, userId]);
      }
    });
  };

  const deleteScan = async (scanId) => {
    fetch(`${apiURL}/scans/${scanId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${await admin?.getIdToken()}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        setDeletedScans([...deletedScans, scanId]);
      }
    });
  };

  React.useEffect(() => {
    fetch(`${apiURL}/status`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data);
      })
      .catch(() =>
        setStatus({
          database: false,
          s3: false,
          firebase: false,
        }),
      );

    fetch(`${apiURL}/scans`)
      .then((res) => res.json())
      .then((data) => {
        setScans(data);
      })
      .catch(() => {
        setScans([
          {
            scanId: "Error",
            userId: "Error",
            s3ImagePath: "Error",
            createdAt: "Error",
            injuryDetected: "Error",
            confidenceLevel: "Error",
          },
        ]);
      });

    fetch(`${apiURL}/users`)
      .then((res) => res.json())
      .then((data) => {
        setUsers(data);
      })
      .catch(() => {
        setUsers([
          {
            userId: "Error",
            email: "Error",
            uuid: "Error",
            name: "Error",
            isAdmin: "Error",
            createdAt: "Error",
            updatedAt: "Error",
          },
        ]);
      });
  }, []);

  return (
    <div className="Admin page">
      <div className="title-container">
        <h1 className="title">Admin</h1>
      </div>
      <section className="section">
        <h2>Status</h2>
        {Object.keys(status).map((key) => (
          <p key={key}>
            {key}: {status[key] ? "✅" : "❌"}
          </p>
        ))}
      </section>

      <section className="section">
        <h2>Scans</h2>
        <table className="scans">
          <thead>
            <tr>
              <th>scan_id</th>
              <th>user_id</th>
              <th>s3_image_path</th>
              <th>created_at</th>
              <th>injury_detected</th>
              <th>confidence_level</th>
              <th>actions</th>
            </tr>
          </thead>
          <tbody className={`${scans.length === 0 ? "loading" : ""}`}>
            <tr className="loading-spinner" />
            {scans.map((scan) => (
              <tr
                key={scan.scanId}
                onMouseEnter={() => handleMouseEnter(scan.scanId)}
                onMouseLeave={() => handleMouseLeave()}
                className={deletedScans.includes(scan.scanId) ? "deleted" : ""}
              >
                <td>{scan.scanId}</td>
                <td>{scan.userId}</td>
                <td className="image-cell">
                  <a
                    href={scan.s3ImagePath}
                    target="_blank"
                    rel="noreferrer"
                    className="secondary"
                  >
                    {scan.s3ImagePath}
                  </a>
                  {hoveredImageKey === scan.scanId && (
                    <img
                      className="hovered-image"
                      src={scan.s3ImagePath}
                      alt="CT scan"
                    />
                  )}
                </td>
                <td>{scan.createdAt}</td>
                <td>{scan.injuryDetected}</td>
                <td>{scan.confidenceLevel}</td>
                <td>
                  <button
                    type="button"
                    className="secondary"
                    onClick={() => deleteScan(scan.scanId)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      <section className="section">
        <h2>Users</h2>
        <table className="users">
          <thead>
            <tr>
              <th>user_id</th>
              <th>email</th>
              <th>uuid</th>
              <th>name</th>
              <th>is_admin</th>
              <th>created_at</th>
              <th>updated_at</th>
              <th>actions</th>
            </tr>
          </thead>
          <tbody className={`${users.length === 0 ? "loading" : ""}`}>
            <tr className="loading-spinner" />
            {users.map((user) => (
              <tr
                key={user.userId}
                className={deleted.includes(user.userId) ? "deleted" : ""}
              >
                <td>{user.userId}</td>
                <td>{user.email}</td>
                <td>{user.uuid}</td>
                <td>{user.name}</td>
                <td>{user.isAdmin ? "true" : "false"}</td>
                <td>{user.createdAt}</td>
                <td>{user.updatedAt}</td>
                <td>
                  <button
                    type="button"
                    className="secondary"
                    onClick={() => deleteUser(user.userId)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default Admin;
