import React from "react";
import "./Team.scss";

function Team() {
  return (
    <div className="Team page">
      <div className="title-container">
        <h1 className="title">Team</h1>

        <h2 className="subtitle">Meet the team behind AbdomAID.</h2>
      </div>
      <section className="section">
        <h2>
          The AbdomAID team is comprised of a diverse group of students from
          across the world, united by a passion for improving healthcare.
        </h2>
        <p>
          AbdomAID is the product of a collaboration between students from the
          University of Toronto and the University of Waterloo, with members
          hailing from Canada, the United States, and India. The team is
          comprised of students from a variety of backgrounds, including
          computer science, engineering, and life sciences. The team is united
          by a shared passion for improving healthcare through the development
          of innovative technologies, and AbdomAID represents the culmination of
          this shared vision.
        </p>
      </section>
    </div>
  );
}

export default Team;
