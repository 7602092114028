import "./Home.scss";
import React from "react";

function Home() {
  return (
    <div className="Home page">
      <div className="title-container">
        <h1 className="title">AbdomAID</h1>

        <h2 className="subtitle">
          World class AI solution for abdominal trauma detection.
        </h2>

        <div className="buttons">
          <a href="/register" className="primary">
            Register
          </a>
          <a href="/demo" className="secondary">
            Try Demo
          </a>
        </div>
      </div>
      <section className="section">
        <h2>
          Leveraging the latest advancements in AI for rapid and accurate
          analysis
        </h2>
        <p>
          AbdomAID represents the cutting edge in medical diagnostic AI,
          offering unparalleled support in the detection and assessment of
          abdominal trauma. Harnessing the most recent breakthroughs in
          artificial intelligence, AbdomAID provides healthcare professionals
          with a swift and precise tool for analyzing complex injury patterns,
          making for faster decision-making and potentially life-saving
          interventions. User-friendly interfaces and robust analytical
          capabilities set AbdomAID apart as a leading solution. Explore its
          features through a comprehensive demo and to delve into its
          capabilities by reading more about its innovative approach to trauma
          care.
        </p>
      </section>
    </div>
  );
}

export default Home;
