import React, { useState, useRef, useEffect } from "react";
import "./Register.scss";
import { signInWithEmailAndPassword } from "firebase/auth";
// import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase-config";

// const api = "http://localhost:5000/abdomaidd/us-central1/api";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState("");
  const [loading, setLoading] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();

  useEffect(() => {
    emailRef.current?.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    setTimeout(async () => {
      try {
        // const userCredential =
        await signInWithEmailAndPassword(auth, email, password);
        // TODO: handle case where database mutation fails
        // const response = await fetch(`${api}/createUser`, {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({
        //     first_name: "",
        //     last_name: "",
        //     user_uid: userCredential.user.uid,
        //     email: userCredential.user.email,
        //   }),
        // }).catch(() => {
        //   throw new Error("Error creating user");
        // });
        // if (!response.ok) {
        //   // delete user by uid if user creation fails
        //   await auth.currentUser?.delete();
        //   throw new Error("Error creating user");
        // } else {
        window.location.href = "/profile";
        // }
      } catch (error) {
        switch (error.code) {
          case "auth/invalid-email":
            setFormError("Invalid email");
            break;
          case "auth/user-disabled":
            setFormError("User disabled");
            break;
          case "auth/user-not-found":
            setFormError("User not found");
            break;
          case "auth/invalid-login-credentials":
            setFormError("Invalid login credentials");
            break;
          case "auth/missing-password":
            setFormError("Missing password");
            break;
          default:
            // eslint-disable-next-line no-console
            console.error(error);
            setFormError("Error logging in. Try again later.");
            break;
        }
        setLoading(false);
      }
    }, 500);
  };

  return (
    <div className="Register">
      <div className="title-container">
        <h1 className="title">Login</h1>
        <h2 className="subtitle">
          Login to your AbdomAID account. Or{" "}
          <a className="underline" href="/register">
            register
          </a>{" "}
          if you don&apos;t have an account.
        </h2>
      </div>

      <form className="form-container">
        <fieldset className="register-form" disabled={loading}>
          <div className="form-header">
            <img src="logo.png" alt="AbdomAID logo" className="logo" />
            <span className="title">AbdomAID</span>
          </div>
          <label htmlFor="email">
            Email
            <input
              type="text"
              name="username"
              placeholder="Enter your email"
              ref={emailRef}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label htmlFor="password">
            Password
            <input
              type="password"
              name="password"
              placeholder="Enter a password"
              ref={passwordRef}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <button
            type="submit"
            className={`primary ${loading ? "loading" : ""}`}
            onClick={handleSubmit}
            disabled={loading}
          >
            Login
          </button>

          {formError && <p className="error">{formError}</p>}
          <div className="support">
            <a href="/reset" className="secondary">
              Forgot Password
            </a>
            <a href="/help" className="secondary">
              Help
            </a>
          </div>
        </fieldset>
      </form>
    </div>
  );
}

export default Login;
