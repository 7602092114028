import React from "react";
import "./Profile.scss";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase-config";

function Profile() {
  const [user] = useAuthState(auth);

  if (!user) {
    return (
      <div className="Profile page">
        <div className="title-container">
          <h1 className="title">Profile</h1>
          <h2 className="subtitle">View and edit your profile information.</h2>
        </div>
        <section className="section">
          <h2>Account Information</h2>
          <p>You must be logged in to view your profile.</p>
        </section>
      </div>
    );
  }

  return (
    <div className="Profile page">
      <div className="title-container">
        <h1 className="title">Profile</h1>
        <h2 className="subtitle">View and edit your profile information.</h2>
      </div>
      <section className="section">
        <h2>Account Information</h2>
        <p>
          <strong>Email:</strong> {user.email}
        </p>
        <p>
          <strong>UID:</strong> {user.uid}
        </p>
      </section>
    </div>
  );
}

export default Profile;
