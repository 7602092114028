import React, { useState, useRef, useEffect } from "react";
import "./Register.scss";
import { createUserWithEmailAndPassword } from "firebase/auth";
// import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase-config";
import { apiURL } from "../api-config";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState("");
  const [loading, setLoading] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();

  useEffect(() => {
    emailRef.current?.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    setTimeout(async () => {
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password,
        );
        const response = await fetch(`${apiURL}/users`, {
          // use form data instead of json
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            uuid: userCredential.user.uid,
            email: userCredential.user.email,
          }),
        }).catch(async () => {
          await auth.currentUser?.delete();
          throw new Error("Error creating user: 505");
        });
        if (!response.ok) {
          await auth.currentUser?.delete();
          // eslint-disable-next-line no-console
          console.error(await response.text());
          throw new Error("Error creating user: 500");
        } else {
          window.location.href = "/profile";
        }
      } catch (error) {
        switch (error.code) {
          case "auth/invalid-email":
            setFormError("Invalid email");
            break;
          case "auth/user-disabled":
            setFormError("User disabled");
            break;
          case "auth/email-already-in-use":
            setFormError("Email already in use");
            break;
          case "auth/weak-password":
            setFormError("Password too weak");
            break;
          case "auth/missing-password":
            setFormError("Missing password");
            break;
          default:
            // eslint-disable-next-line no-console
            console.error(error);
            setFormError("Error creating user. Try again later.");
            break;
        }
        setLoading(false);
      }
    }, 500);
  };

  return (
    <div className="Register">
      <div className="title-container">
        <h1 className="title">Register</h1>
        <h2 className="subtitle">
          Register for an account to use AbdomAID. Or{" "}
          <a className="underline" href="/login">
            login
          </a>{" "}
          if you already have an account.
        </h2>
      </div>

      <form className="form-container">
        <fieldset className="register-form" disabled={loading}>
          <div className="form-header">
            <img src="logo.png" alt="AbdomAID logo" className="logo" />
            <span className="title">AbdomAID</span>
          </div>
          <label htmlFor="email">
            Email
            <input
              type="text"
              name="username"
              placeholder="Enter your email"
              ref={emailRef}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label htmlFor="password">
            Password
            <input
              type="password"
              name="password"
              placeholder="Enter a password"
              ref={passwordRef}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <button
            type="submit"
            className={`primary ${loading ? "loading" : ""}`}
            onClick={handleSubmit}
            disabled={loading}
          >
            Register
          </button>

          {formError && <p className="error">{formError}</p>}
        </fieldset>
      </form>
    </div>
  );
}

export default Register;
