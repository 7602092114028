import React from "react";
import externalLinkIcon from "../images/external-link-icon.svg";
import "./About.scss";

function About() {
  return (
    <div className="About page">
      <div className="title-container">
        <h1 className="title">About</h1>

        <h2 className="subtitle">
          AbdomAID is an application that aims to help with medical abdominal
          injury diagnosis. It is a work in progress. It is not a substitute for
          medical advice.
        </h2>
      </div>
      <section className="section">
        <h2>Project Stakeholders</h2>
        <p>
          The key stakeholders for this project include medical practitioners,
          patients, and healthcare institutions.
        </p>
      </section>

      <section className="section">
        <h2>What Will Be Built</h2>
        <p>
          This unsupervised capstone project aims to utilize artificial
          intelligence (AI) to assist healthcare professionals in detecting
          abdominal injuries and determining their severity using provided CT
          scans. The project will be divided into three main components: the
          machine learning model, a web interface for presentation, and an
          application serving as an interface to the model.
        </p>
      </section>

      <section className="section">
        <h2>How Will It Be Built</h2>
        <p>
          The machine learning model will be designed to identify abdominal
          trauma in CT scans. The model will identify any existing injuries in
          the abdominal organs and detect extravasation. We are using the data
          from Kaggle under the RSNA 2023 Abdominal trauma detector competition.
          The provided data contains 460GB of CT scan images in the DICOM
          format. The dataset contains the information about the state of bowel,
          extravasation, liver, kidney, spleen for each CT scan. Based on this
          dataset we are creating a ML model that will classify these traumas.
          The development of the ML model will primarily involve programming
          tools like Python, TensorFlow, and other relevant AI libraries. The
          model will be deployed on a server, such as AWS, Azure, or GCP,
          capable of receiving CT scans in the DICOM format.
        </p>
        <p>
          The project will also include a dedicated webpage to effectively
          communicate its purpose and functionality to stakeholders. This
          webpage will consist of static pages providing explanations and
          demonstrations of the application&apos;s capabilities and its
          underlying processes. To create this webpage, a front-end framework
          will be used, and it will be hosted on a cloud-based solution.
        </p>
        <p>
          The application, serving as an interface for doctors to interact with
          the model, may take the form of a mobile app, desktop software, or a
          web application. The final decision on the type of application will be
          made following discussions with stakeholders, particularly healthcare
          professionals. A combination of a database and a backend server hosted
          on a cloud platform will be used for data storage and computational
          tasks.
        </p>
      </section>

      <section className="section">
        <h2>Credits</h2>
        <p>
          This project was created by a team of undergraduate students from
          McMaster University.
          <ul className="team">
            <li>
              <a
                href="https://amrthabit.com"
                target="_blank"
                className="secondary"
                rel="noreferrer"
              >
                <span>Amr Thabit </span>
                <img
                  src={externalLinkIcon}
                  alt="external link"
                  className="icon"
                />
              </a>
            </li>
            <li>
              <a href="/about" target="_blank" className="secondary">
                <span>Viktor Turchenko</span>
                <img
                  src={externalLinkIcon}
                  alt="external link"
                  className="icon"
                />
              </a>
            </li>
            <li>
              <a href="/about" target="_blank" className="secondary">
                <span>Nojus Niuklys</span>
                <img
                  src={externalLinkIcon}
                  alt="external link"
                  className="icon"
                />
              </a>
            </li>
            <li>
              <a href="/about" target="_blank" className="secondary">
                <span>Wenxuan Chen</span>
                <img
                  src={externalLinkIcon}
                  alt="external link"
                  className="icon"
                />
              </a>
            </li>
          </ul>
        </p>
      </section>
    </div>
  );
}

export default About;
