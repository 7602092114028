import React from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase-config";
import "./Register.scss";

function Reset() {
  const [email, setEmail] = React.useState("");
  const [formError, setFormError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    setTimeout(async () => {
      try {
        await sendPasswordResetEmail(auth, email);
        setFormError("Password reset email sent");
      } catch (error) {
        switch (error.code) {
          case "auth/missing-email":
            setFormError("Missing email");
            break;
          case "auth/invalid-email":
            setFormError("Invalid email");
            break;
          case "auth/user-not-found":
            setFormError("User not found");
            break;
          default:
            // eslint-disable-next-line no-console
            console.error(error);
            setFormError("Error resetting password");
            break;
        }
      }
      setEmail("");
      setLoading(false);
    }, 500);
  };

  return (
    <div className="Register">
      <div className="title-container">
        <h1 className="title">Reset</h1>
        <h2 className="subtitle">
          Enter your email address to receive a password reset link.
        </h2>
      </div>

      <form className="form-container">
        <fieldset className="register-form" disabled={loading}>
          <div className="form-header">
            <img src="logo.png" alt="AbdomAID logo" className="logo" />
            <span className="title">AbdomAID</span>
          </div>
          <label htmlFor="email">
            Email
            <input
              type="text"
              name="username"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <button
            type="submit"
            className={`primary ${loading ? "loading" : ""}`}
            onClick={handleSubmit}
            disabled={loading}
          >
            Reset Password
          </button>

          {formError && <p className="error">{formError}</p>}
        </fieldset>
      </form>
    </div>
  );
}

export default Reset;
