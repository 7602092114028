import React from "react";
import "./Help.scss";

function Help() {
  return (
    <div className="Help page">
      <div className="title-container">
        <h1 className="title">Help</h1>
        <h2 className="subtitle">
          For support, contact{" "}
          <a href="mailto:thabia1@mcmaster.ca" className="underline">
            Amr Thabit
          </a>
          .
        </h2>
      </div>
    </div>
  );
}

export default Help;
